
import { getReport } from '@/api/reports.api';
import { ref } from '@vue/reactivity';
import { DocumentDownloadIcon, XCircleIcon } from '@heroicons/vue/solid';
import { defineComponent, onMounted } from 'vue';
import { watch } from '@vue/runtime-core';

import DatePickerGroup from '@/components/DatePickerGroup.vue';

import airlinesList from '@/constants/availableAirlines';
import SelectorList from '@/components/SelectorList.vue';

export default defineComponent({
  name: 'report',
  components: {
    DocumentDownloadIcon,
    XCircleIcon,
    DatePickerGroup,
    SelectorList,
  },
  setup() {
    const reports = ref([] as any[]);
    const reportsFiltered = ref([] as any[]);
    const searchQuery = ref('');
    const error = ref('');
    const validationError = ref('');
    const downloadLoading = ref(false);
    const downloadFailed = ref(false);
    const startDate = ref(new Date());
    const endDate = ref(new Date());

    const selectedAirlines = ref([] as string[]);
    const airlineOptions = ref([] as { name: string; id: string }[]);

    const onAirlineChange = (newValue: string[]) => {
      selectedAirlines.value = newValue;
    };

    watch(
      () => searchQuery.value || reports.value,
      () => {
        const search = searchQuery.value.toLowerCase().trim();
        reportsFiltered.value =
          !search || !reports.value.length
            ? reports.value
            : reports.value?.filter(
                (report: any) =>
                  report.startDate?.toString().toLowerCase().includes(search) ||
                  report.endDate?.toString().toLowerCase().includes(search) ||
                  (report.airlines.join(', ') || 'all')
                    .toLowerCase()
                    .includes(search)
              );
      },
      { immediate: true }
    );

    const newReport = function (
      startDate: Date,
      endDate: Date,
      airlines: string[] = []
    ) {
      validationError.value = '';
      const startDateOffset = startDate.getTimezoneOffset();
      const endDateOffset = endDate.getTimezoneOffset();

      const offsetStartDate = new Date(
        startDate.getTime() - startDateOffset * 60 * 1000
      );
      const offsetEndDate = new Date(
        endDate.getTime() - endDateOffset * 60 * 1000
      );

      const formatedStartDate = offsetStartDate.toISOString().split('T')[0];
      const formatedEndDate = offsetEndDate.toISOString().split('T')[0];

      reports.value.push({
        startDate: formatedStartDate,
        endDate: formatedEndDate,
        airlines: airlines,
        downloadLoading: false,
        downloadFailed: false,
      });
    };

    const downloadCsv = function (
      reportIndex: number,
      startDate: string,
      endDate: string,
      airlines: string[]
    ) {
      (async () => {
        reports.value[reportIndex].downloadLoading = true;
        try {
          const response = await getReport(startDate, endDate, airlines);
          let csv_data = response.data.csv.data;
          let filename = response.data.csv.filename;

          const anchor = document.createElement('a');
          anchor.href =
            'data:text/csv; charset=utf-8,' + encodeURIComponent(csv_data);
          anchor.target = '_blank';
          anchor.download = filename + '.csv';
          anchor.click();

          reports.value[reportIndex].downloadLoading = false;
        } catch (e) {
          error.value = `Error: the server responded with '${
            (e as any).response.status
          }: ${(e as any).response.statusText}'`;
          reports.value[reportIndex].downloadLoading = false;
          reports.value[reportIndex].downloadFailed = true;
        }
      })();
    };

    const onDateChange = (data: { startDate: Date; endDate: Date }) => {
      startDate.value = data.startDate;
      endDate.value = data.endDate;
    };

    const setAirlinesOptions = () => {
      const options: { name: string; id: string }[] = airlinesList.map(
        (airline) => ({ name: airline, id: airline })
      );
      airlineOptions.value = options;
    };

    onMounted(() => {
      setAirlinesOptions();
    });

    return {
      reports,
      reportsFiltered,
      searchQuery,
      error,
      validationError,
      downloadLoading,
      downloadFailed,
      startDate,
      endDate,
      selectedAirlines,
      onAirlineChange,
      newReport,
      downloadCsv,
      onDateChange,
      airlineOptions,
    };
  },
});
